import { GetAccountStatementsResponse } from '~/networking/api';
import { MakeMangedRequestOptions } from '~/networking/client';
import { httpRequest, Response } from '~/networking/utils';

export interface GetAccountStatmentsParams {
    year?: string;
    pageSize?: number;
    pageToken?: string;
}

export const getAccountStatements = ({ getHeaders, getRequestUrl, getPortfolioId }: MakeMangedRequestOptions) => {
    return async (
        params?: GetAccountStatmentsParams,
        portfolioID?: string
    ): Promise<Response<GetAccountStatementsResponse>> => {
        const headers = getHeaders(true);
        const urlParams = new URLSearchParams({
            year: params?.year || '',
            pageSize: String(params?.pageSize || 10),
            pageToken: params?.pageToken || ''
        });
        const useablePortfolioID = portfolioID || getPortfolioId();
        const endpoint = getRequestUrl(`/v2/documents/${useablePortfolioID}/monthly?${urlParams.toString()}`);
        return httpRequest.get<GetAccountStatementsResponse>(endpoint, { headers });
    };
};
