import React, { HTMLAttributes, MouseEventHandler } from 'react';
import classNames from 'classnames';
import Link from 'next/link';

import { TrackEvent } from '~/components/modules/Tracking/TrackEvent';
import { AnalyticsEventNames } from '~/constants/AnalyticsEventMap';
import { ClassNamesProps } from '~/models/ClassNamesProps';
import { baseRoutes } from '~/routes/base';
import { IconHamburger, IconUser } from '~/vinovest-design-system/components/Icons';

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement & HTMLAnchorElement>, ClassNamesProps {
    variant?: keyof typeof ButtonType;
    href?: string;
    disabled?: boolean;
    padding?: string | string[];
    type?: 'button' | 'submit' | 'reset' | undefined;
    target?: '_blank' | '_self' | '_parent' | '_top' | 'framename';
    font?: string;
}

interface MenuButtonProps extends ClassNamesProps {
    color: string;
    toggleMobileNav: () => void;
}

interface InlineLinkProps extends ClassNamesProps {
    href?: string;
    onClick?: MouseEventHandler<HTMLButtonElement>;
}

interface ButtonAccountProps {
    color?: string | null;
}

export interface ButtonVariantProps {
    primary: string;
    secondary: string;
    tertiary: string;
    quaternary: string;
    gray: string;
    green: string;
    platinum: string;
    platinumDark: string;
    red: string;
    solidRed: string;
    transparent: string;
    brownBorderTransparent: string;
    custom: string;
}

export const Button: React.FC<ButtonProps> = ({
    children,
    value,
    type = 'button',
    className,
    classnames,
    overwriteClassName = true,
    ...props
}) => {
    const { disabled, variant, padding, href, target, font } = props;
    const allPadding = Array.isArray(padding) ? padding.join(' ') : padding;

    return href ? (
        <Link
            href={href}
            target={target}
            className={
                overwriteClassName && className != null
                    ? className
                    : classNames(
                          font ? font : 'font-vinovestmedium ',
                          base,
                          'inline-block',
                          !disabled && ButtonType[variant || 'primary'],
                          disabled && { [ButtonDisabled[variant || 'gray']]: disabled },
                          allPadding || 'px-7 py-3 lg:py-4',
                          [classnames, className]
                      )
            }
            {...props}
        >
            {children}
        </Link>
    ) : (
        <button
            type={type}
            value={value}
            className={
                overwriteClassName && className != null
                    ? className
                    : classNames(
                          font ? font : 'font-vinovestmedium ',
                          base,
                          !disabled && ButtonType[variant || 'primary'],
                          disabled && { [ButtonDisabled[variant || 'gray']]: disabled },
                          allPadding || 'px-7 py-3 lg:py-4',
                          [classnames, className]
                      )
            }
            {...props}
        >
            {children}
        </button>
    );
};

export const ButtonMenu: React.FC<MenuButtonProps> = ({ toggleMobileNav, color, classnames }) => {
    return (
        <button type={'button'} onClick={() => toggleMobileNav()} className={classnames} aria-label={'menu'}>
            <IconHamburger height={'22'} width={'32'} stroke={color} />
        </button>
    );
};

export const ButtonAccount: React.FC<ButtonAccountProps> = ({ color }) => {
    return (
        <TrackEvent eventName={AnalyticsEventNames.AccountSettings}>
            <Link href={baseRoutes.account.href} type={'button'} className={'flex items-center justify-end'}>
                <IconUser stroke={`${color != null ? color : '#000'}`} width={'36px'} height={'36px'} />
            </Link>
        </TrackEvent>
    );
};

export const InlineLink: React.FC<InlineLinkProps> = ({ children, href, classnames, onClick }) => {
    return href ? (
        <Link href={href} className={`${classnames} text-[#0066CC] hover:opacity-50`}>
            {children}
        </Link>
    ) : (
        <button type={'button'} className={`${classnames} text-[#0066CC] hover:opacity-50`} onClick={onClick}>
            {children}
        </button>
    );
};

const base = 'text-14 rounded max-h-64 hover:bg-opacity-90';

const ButtonType: ButtonVariantProps = {
    primary: 'bg-brown-600 text-white',
    secondary:
        'text-platinum-800 border border-platinum-800 hover:bg-platinum-800 hover:text-white hover:bg-opacity-100',
    tertiary: 'text-brown-600 hover:underline',
    quaternary: 'text-white border-white border',
    gray: 'bg-neutrals-200 text-platinum-800',
    green: 'bg-green-550 text-white',
    platinum: 'bg-platinum-800 text-white',
    platinumDark: 'bg-platinum-800 text-brown-400',

    red: 'text-red-600 hover:underline',
    solidRed: 'text-white bg-red-600',
    transparent: 'bg-inherit border-[#E5E5E5] border border-2 font-vinovest',
    brownBorderTransparent: 'text-brown-300 border border-brown-300 bg-transparent text-12 lg:text-12',
    custom: ''
};

const ButtonDisabled: ButtonVariantProps = {
    primary: 'pointer-events-none text-brown-100 bg-brown-400',
    secondary: 'pointer-events-none text-gray-400 border border-gray-300',
    tertiary: 'pointer-events-none text-brown-400 hover:no-underline',
    red: 'pointer-events-none text-red-400 hover:no-underline',
    solidRed: 'pointer-events-none text-red-400 hover:no-underline',
    quaternary: 'pointer-events-none text-gray-400 bg-gray-200',
    gray: 'pointer-events-none text-white bg-gray-300',
    green: 'pointer-events-none text-gray-400 bg-gray-200',
    platinum: 'pointer-events-none bg-neutrals-200 text-gray-400',
    platinumDark: 'pointer-events-none bg-neutrals-200 text-gray-400',
    transparent: 'pointer-events-none bg-neutrals-200 border-platinum-800',
    brownBorderTransparent: 'text-brown-300 border border-brown-300 bg-transparent',
    custom: 'pointer-events-none'
};
