import { useRouter } from 'next/router';
import { Box, Flex } from '~/vinovest-design-system/components';
import { MenuLinkNav } from '~/vinovest-design-system/components/Navigation/components/MenuLinkNav';
import { Logo } from '../Logo/Logo';
import { OnboardingTourTargetContainer } from '../OnboardingTour/components';
import { DASHBOARD_ONBOARDING_TOUR_STEP_ONE_BASE_SELECTOR } from '../OnboardingTour/Dashboard/DashboardOnboardingTour';
import { Button, ButtonAccount } from '~/vinovest-design-system/components/Button';
import { NAVIGATION_DASHBOARD_LINKS } from './Navigation.data';
import { useContext, useEffect, useMemo, useState } from 'react';
import { PortfolioTypes } from '~/models/PortfolioType';
import { baseRoutes } from '~/routes/base';
import { DepositContext } from '~/context/Deposit/DepositContext';
import { DepositTransferModalKey, UrlModalHelper } from '~/helpers/UrlModalHelper';
import { TrackEvent } from '../Tracking/TrackEvent';
import { AddFundsInitiators, AnalyticsEventNames } from '~/tracking';
import { useManagedPortfolioQuery } from '~/hooks/queries/portfolios/managed/useManagedPortfolioQuery';
// import { useTradingPortfolioQuery } from '~/hooks/queries/portfolios/trading/useTradingPortfolioQuery';
import { GlobalSubNavigation } from './GlobalSubNavigation';
import { useWindowSize } from '~/hooks/useWindowSize';

export interface GlobalNavigationProps {
    dashboard: boolean;
    needsOnboardingTour: boolean;
}

export const GlobalNavigation: React.FC<GlobalNavigationProps> = ({ dashboard, needsOnboardingTour }) => {
    const { isDesktop } = useWindowSize();
    const router = useRouter();
    const [navLocation, setNavLocation] = useState('');
    const topNavLocation = `/${navLocation}`;
    const [isManaged, setIsManaged] = useState(false);
    const { setDestinationPortfolio, setDepositAmount } = useContext(DepositContext);
    const { data: managedPortfolio, isSuccess } = useManagedPortfolioQuery();
    // const { data: tradePortfolio } = useTradingPortfolioQuery();
    const trading = useMemo(() => router.pathname.includes(baseRoutes.trade.href), [router.pathname]);
    const whiskey = useMemo(() => router.pathname.includes(baseRoutes.whiskey.href), [router.pathname]);
    const wineOffer = useMemo(() => router.pathname.startsWith('/managed/offer/'), [router.pathname]);

    const minWineDeposit = useMemo(() => {
        if (isSuccess && managedPortfolio && managedPortfolio.cash && managedPortfolio.positionTotal) {
            const cashAmount = typeof managedPortfolio.cash.amount === 'number' ? managedPortfolio.cash.amount : 0;
            const positionTotalAmount =
                typeof managedPortfolio.positionTotal.amount === 'number' ? managedPortfolio.positionTotal.amount : 0;
            return cashAmount + positionTotalAmount <= 100_000;
        }
        return false;
    }, [isSuccess, managedPortfolio]);

    // deposit pages have no header 'add funds'
    const deposit = useMemo(() => router.pathname.startsWith(baseRoutes.upsell.href), [router.pathname]);

    useEffect(() => {
        if (router.pathname.startsWith('/manage')) {
            return setNavLocation('managed');
        }
        if (router.pathname.startsWith('/whiskey')) {
            return setNavLocation('whiskey');
        }
        if (router.pathname.startsWith('/trade')) {
            return setNavLocation('trade');
        }
        return setNavLocation('');
    }, [router]);

    const portfolio = useMemo(() => {
        if (trading) {
            return PortfolioTypes.trading;
        }
        if (whiskey) {
            return PortfolioTypes.whiskey;
        }
        setIsManaged(true);
        return PortfolioTypes.managed;
    }, []);

    const handleAddFunds = () => {
        switch (portfolio) {
            case PortfolioTypes.managed: {
                setIsManaged(true);
                setDestinationPortfolio(PortfolioTypes.managed);
                setDepositAmount(0);
                break;
            }
            case PortfolioTypes.whiskey: {
                setIsManaged(false);
                setDestinationPortfolio(PortfolioTypes.whiskey);
                setDepositAmount(0);
                break;
            }
            case PortfolioTypes.trading: {
                setIsManaged(false);
                setDestinationPortfolio(PortfolioTypes.trading);
                setDepositAmount(0);
                break;
            }
            default: {
                // Assumes default type is PortfolioTypes.managed
                setDestinationPortfolio(PortfolioTypes.managed);
                setDepositAmount(0);
                break;
            }
        }

        const wineOfferRoute = baseRoutes.managed.href;
        const defaultRoute = '/managed/offer/wine-offer';

        let routeToOpen;
        if (wineOffer) {
            routeToOpen = wineOfferRoute;
        } else if (minWineDeposit && isManaged) {
            routeToOpen = defaultRoute;
        } else {
            routeToOpen = '';
        }

        UrlModalHelper.openModal(router, DepositTransferModalKey, routeToOpen);
    };

    const AddFundsButton = () => {
        if (deposit) {
            return null;
        }
        return (
            <TrackEvent eventName={AnalyticsEventNames.AddFunds} metaData={{ initiator: AddFundsInitiators.Header }}>
                <Button
                    variant={'custom'}
                    className={
                        'text-14 rounded bg-green-600 text-white p-3 capitalize hover:bg-opacity-90 whitespace-nowrap'
                    }
                    onClick={handleAddFunds}
                >
                    Add Funds
                </Button>
            </TrackEvent>
        );
    };

    // const hideTradeNav = tradePortfolio?.totalNumOfBottles === 0 && tradePortfolio?.totalTradingPortfolioValue === 0;

    return (
        <Flex classnames={'w-full flex-grow bg-[#ffffff] flex-col'}>
            <Flex classnames={'items-center justify-between w-full relative mx-auto h-[75px] px-2 md:px-6'}>
                <ul className={'hidden md:flex md:items-center w-[40%] gap-[10px]'}>
                    {NAVIGATION_DASHBOARD_LINKS.map((link) => {
                        return (
                            <>
                                <MenuLinkNav link={String(link.to)} topLevel={topNavLocation} key={link.label}>
                                    {link.label}
                                </MenuLinkNav>
                            </>
                        );
                    })}
                </ul>
                <Flex classnames={'hidden md:flex md:items-center'}>
                    <Box classnames={`${isDesktop || (!isDesktop && dashboard) ? 'block w-[75px]' : 'hidden'} `}>
                        <Logo type={'standard'} />
                    </Box>
                </Flex>
                <Flex classnames={'hidden md:block min-w-[40%]'}>
                    <Flex classnames={'w-full gap-6 items-center flex justify-end self-end'}>
                        <Box classnames={'ml-8'}>
                            {dashboard ? (
                                <OnboardingTourTargetContainer
                                    needsOnboarding={needsOnboardingTour}
                                    stepNumber={0}
                                    baseTargetSelector={DASHBOARD_ONBOARDING_TOUR_STEP_ONE_BASE_SELECTOR}
                                    containerClassNames={'w-min'}
                                    maximumAllowedScreenSize={'desktop'}
                                >
                                    <AddFundsButton />
                                </OnboardingTourTargetContainer>
                            ) : (
                                <AddFundsButton />
                            )}
                        </Box>
                        <Box classnames={'hidden md:block'}>
                            <ButtonAccount />
                        </Box>
                    </Flex>
                </Flex>
            </Flex>
            <GlobalSubNavigation />
        </Flex>
    );
};
