import { useEffect, useState } from 'react';

import { UrlModalHelper } from '~/helpers/UrlModalHelper';
import { autorun } from 'mobx';

export const useUrlModal = (urlKey: string) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        return autorun(() => {
            setOpen(UrlModalHelper.isModalOpen(urlKey));
        });
    }, [urlKey]);

    const toggleModal = () => {
        // Opened but should close
        if (open) {
            UrlModalHelper.close(urlKey);
        }

        // Not Opened but should open
        if (!open) {
            UrlModalHelper.open(urlKey);
        }
    };
    const closeModal = () => {
        UrlModalHelper.close(urlKey);
    };

    const openModal = () => {
        UrlModalHelper.open(urlKey);
    };

    return { open, toggleModal, closeModal, openModal };
};
