import { useRouter } from 'next/router';
import { Flex } from '~/vinovest-design-system/components';
import { MenuLinkNav } from '~/vinovest-design-system/components/Navigation/components/MenuLinkNav';
import { NAVIGATION_MANAGED_LINKS, NAVIGATION_TRADE_LINKS, NAVIGATION_WHISKEY_LINKS } from './Navigation.data';
import { useEffect, useState } from 'react';

export const GlobalSubNavigation: React.FC = () => {
    const router = useRouter();
    const [navLocation, setNavLocation] = useState('');

    useEffect(() => {
        if (router.pathname.startsWith('/manage')) {
            return setNavLocation('managed');
        }
        if (router.pathname.startsWith('/whiskey')) {
            return setNavLocation('whiskey');
        }
        if (router.pathname.startsWith('/trade')) {
            return setNavLocation('trade');
        }
        return setNavLocation('');
    }, [router]);

    const showSubNav = navLocation === 'managed' || navLocation === 'whiskey' || navLocation === 'trade';
    return (
        <>
            {showSubNav && (
                <Flex
                    classnames={
                        'items-center w-full relative max-w-[100%] overflow-x-scroll pb-4 md:pb-0 md:overflow-x-hidden md:max-w-[1600px] mx-auto mt-6 md:my-8'
                    }
                >
                    <ul className={'flex items-center relative px-2 md:px-0 max-w-[800px] mx-auto'}>
                        {navLocation === 'managed' && (
                            <>
                                {NAVIGATION_MANAGED_LINKS.map((link) => {
                                    return (
                                        <MenuLinkNav link={String(link.to)} key={link.to}>
                                            {link.label}
                                        </MenuLinkNav>
                                    );
                                })}
                            </>
                        )}
                        {navLocation === 'whiskey' && (
                            <>
                                {NAVIGATION_WHISKEY_LINKS.map((link) => {
                                    return (
                                        <MenuLinkNav link={String(link.to)} key={link.to}>
                                            {link.label}
                                        </MenuLinkNav>
                                    );
                                })}
                            </>
                        )}
                        {navLocation === 'trade' && (
                            <>
                                {NAVIGATION_TRADE_LINKS.map((link) => {
                                    return (
                                        <MenuLinkNav link={String(link.to)} key={link.to}>
                                            {link.label}
                                        </MenuLinkNav>
                                    );
                                })}
                            </>
                        )}
                    </ul>
                </Flex>
            )}
        </>
    );
};
