import { AnalyticsEventNames } from '~/constants/AnalyticsEventMap';
import { baseRoutes, managedRoutes, marketingRoutes, tradeRoutes } from '~/routes/applicationRoutes';
import { whiskeyRoutes } from '~/routes/whiskey';

export const isMatch = (match: string) => match;

export const NAVIGATION_LINKS_GUEST = [
    {
        to: baseRoutes.home.href,
        label: 'Home',
        exact: true,
        isActive: isMatch
    },
    {
        to: marketingRoutes.howItWorks.href,
        label: 'How it Works',
        exact: true,
        isActive: isMatch
    },
    {
        to: baseRoutes.trackRecord.href,
        label: 'Track Record',
        exact: true,
        isActive: isMatch
    },
    {
        to: baseRoutes.aboutUs.href,
        label: 'About',
        exact: true,
        isActive: isMatch
    },
    {
        to: baseRoutes.blog.href,
        label: 'Blog',
        exact: true,
        isActive: isMatch
    }
];

export const NAVIGATION_SUB_LINKS = [
    {
        to: baseRoutes.aboutUs.href,
        label: 'About',
        exact: true,
        isActive: isMatch
    },
    {
        to: marketingRoutes.help.href,
        label: 'FAQ',
        exact: true,
        isActive: isMatch
    }
];

export const NAVIGATION_MANAGED_LINKS = [
    {
        to: managedRoutes.invest.href,
        label: 'Invest'
    },
    {
        to: managedRoutes.portfolio.href,
        label: 'Portfolio'
    },
    {
        to: managedRoutes.transactions.href,
        label: 'Transactions'
    },
    {
        to: managedRoutes.documents.href,
        label: 'Statements'
    }
];

export const NAVIGATION_TRADE_LINKS = [
    {
        to: tradeRoutes.marketplace.href,
        label: 'Marketplace',
        track: {
            eventName: AnalyticsEventNames.SelectMarket,
            metaData: { initiator: 'mobile_navigation' }
        }
    },
    {
        to: tradeRoutes.tradingPortfolio.href,
        label: 'Portfolio',
        track: {
            eventName: AnalyticsEventNames.ViewTradingPortfolio,
            metaData: { initiator: 'mobile_navigation' }
        }
    },
    {
        to: tradeRoutes.activity.href,
        label: 'Activity'
    }
];

export const NAVIGATION_WHISKEY_LINKS = [
    {
        to: whiskeyRoutes.invest.href,
        label: 'Invest',
        track: {
            metaData: { initiator: 'select_whiskey' }
        }
    },
    {
        to: whiskeyRoutes.portfolio.href,
        label: 'Portfolio',
        track: {
            metaData: { initiator: 'select_whiskey_portfolio' }
        }
    },
    {
        to: whiskeyRoutes.activity.href,
        label: 'Transactions',
        track: {
            metaData: { initiator: 'select_whiskey_activity' }
        }
    },
    {
        to: whiskeyRoutes.documents.href,
        label: 'Statements'
    }
];

export const NAVIGATION_DASHBOARD_LINKS = [
    {
        to: baseRoutes.dashboard.href,
        label: 'Home'
    },
    {
        to: managedRoutes.invest.href,
        label: 'Wine'
    },
    {
        to: whiskeyRoutes.invest.href,
        label: 'Whiskey'
    },
    {
        to: tradeRoutes.marketplace.href,
        label: 'Trading',
        track: {
            eventName: AnalyticsEventNames.ViewTradingPortfolio,
            metaData: { initiator: 'mobile_navigation' }
        }
    }
];
