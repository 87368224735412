import { NextRouter, useRouter } from 'next/router';
import { action, makeAutoObservable } from 'mobx';
import { useEffect } from 'react';

export const ConnectSourceModalKey = 'connectPaymentMethod';
export const SelectAPathModalKey = 'selectPath';
export const DepositTransferModalKey = 'depositTransfer';
export const InitialDepositSelection = 'initialDepositSelection';
export const TierPlanComparison = 'tierPlanComparison';
export const BookACallOnboarding = 'bookACallOnboarding';
export const AllPlans = 'allPlans';
export const ReferralOnboarding = 'referralOnboarding';
export const ReferralGlobal = 'referralGlobal';
export const SinglePlan = 'singlePlan';

/** Modal keys that can be used to open/close modals. */
const modalKeys = [
    ConnectSourceModalKey,
    DepositTransferModalKey,
    SelectAPathModalKey,
    InitialDepositSelection,
    BookACallOnboarding,
    TierPlanComparison,
    ReferralOnboarding,
    ReferralGlobal,
    AllPlans
];

export enum ModalEvents {
    Closed = 'closed',
    Opened = 'opened'
}

export interface ModalEventDetails {
    modalKey: string;
    success?: boolean;
}

type CloseCallback = (success: boolean) => void;

/**
 * The url modal helper is designed to work with modals that are conditionally
 * shown or hidden across the site via the URL. This object provides the basic
 * necessities to open / close and determine visibility of those modals.
 */
class ModalStore {
    state: Record<(typeof modalKeys)[number], true> = {};

    // one-time callbacks
    callerCallbacks: Record<string, CloseCallback[]> = {};

    // manually added callbacks, must be removed to prevent memory leaks
    globalCallbacks: Record<string, CloseCallback[]> = {};

    constructor() {
        makeAutoObservable(this, {
            isAnyModalOpen: false,
            isModalOpen: false,
            setOpen: false,
            callerCallbacks: false,
            globalCallbacks: false,
            open: action.bound,
            openModal: action.bound,
            close: action.bound
        });

        if (typeof window !== 'undefined') {
            /* eslint-disable */
            const win = window as any;
            win.Vino = win.Vino || {};
            win.Vino.modals = this;
        }
    }

    /**
     * Used to determine if there is already a modal open on the page that is
     * keyed off of the URL parameters. This is helpful in determining whether or not
     * to show another modal
     * @returns true if there are any URL based modals open
     */
    isAnyModalOpen = (): boolean => {
        return Object.keys(this.state).length > 0;
    };

    /**
     * Used to determine if a provided modal key is set in the URL. This
     * determines whether or not URL based modals are visible or not.
     * @param modalKey the modal key to check for in the URL
     * @returns true if the given modal is open, false otherwise
     */
    isModalOpen = (modalKey: string): boolean => {
        return Boolean(this.state[modalKey]);
    };

    /**
     * Opens the given modal key that is used to conditionally render a modal
     * @param router the NextRouter used to change the URL to show a modal
     * @param modalKey the modal key for the modal to show
     * @param routePath
     * @deprecated old style
     */
    openModal = (router: NextRouter, modalKey: string, routePath?: string) => {
        this.state[modalKey] = true;

        if (routePath) {
            this.callerCallbacks[modalKey] = [(success) => {
                if (success) {
                    void router.push(routePath);
                }
            }]
        }
    };

    /**
     * Sets the URL parameter for the given modal key that is used to conditionally render a modal
     */
    open = (modalKey: string, callback?: CloseCallback | CloseCallback[]) => {
        this.state[modalKey] = true;
        if (callback) {
            this.callerCallbacks[modalKey] = Array.isArray(callback) ? callback : [callback];
        }
    };

    /**
     * Sets the URL parameter for the given modal key that is used to conditionally render a modal
     */
    setOpen = (modalKey: string, open: boolean) => {
        if (open) {
            this.open(modalKey);
        } else if (this.isModalOpen(modalKey)) {
            this.close(modalKey);
        }
    };

    /**
     * Closes the given modal key and should cause the modal to hide
     * @param _router the NextRouter used to change the URL to show a modal
     * @param modalKey the modal key for the modal to show
     * @param success
     * @deprecated old style
     */
    closeModal = (_router: NextRouter, modalKey: string, success?: boolean): void => {
        this.close(modalKey, success);
    };

    /**
     * Closes the given modal key and should cause the modal to hide
     */
    close = (modalKey: string, success: boolean = false): void => {
        delete this.state[modalKey];

        const callbacks = [...(this.callerCallbacks[modalKey] || []), ...(this.globalCallbacks[modalKey] || [])];
        for (const callback of callbacks) {
            callback(success);
        }
        delete this.callerCallbacks[modalKey];
    };

    /**
     * add a new global callback which must be manually removed
     * @param modalKey
     * @param callback
     */
    addCallback = (modalKey: string, callback: CloseCallback | CloseCallback[]) => {
        this.globalCallbacks[modalKey] = [
            ...(this.globalCallbacks[modalKey] || []),
            ...(Array.isArray(callback) ? callback : [callback])
        ]
    }

    /**
     * remove a global callback for a modalKey
     * @param modalKey
     * @param callback
     */
    removeCallback = (modalKey: string, callback: CloseCallback | CloseCallback[]) => {
        if (!this.globalCallbacks[modalKey]) {
            return;
        }
        const callbacks = Array.isArray(callback) ? callback : [callback];
        this.globalCallbacks[modalKey] = this.globalCallbacks[modalKey].filter(c => !callbacks.includes(c));
    }

    /**
     * remove all global callbacks for a modalKey
     * @param modalKey
     */
    removeCallbacks = (modalKey: string) => {
        delete this.globalCallbacks[modalKey];
    }
}

export const UrlModalHelper = new ModalStore();

export const useUrlModalListener = () => {
    const router = useRouter();
    const { query } = router;

    // Listen for changes to the URL and open/close modals based on the URL
    useEffect(() => {
        for (const modalKey of modalKeys) {
            if (query[modalKey] !== undefined) {
                UrlModalHelper.open(modalKey);
                delete query[modalKey];
                void router.replace({ query }, undefined, { shallow: true });
            }
        }
    }, [query, router]);
};
