import React from 'react';
import Image from 'next/legacy/image';
import styled from 'styled-components';

import LauraImg from '~/assets/img/support/Laura.png';
import Phone from '~/assets/img/support/phone.svg';

import DownloadIosApp from './DownloadIosApp';
import { FooterTitle } from './Footer.styled';

const FooterSupport: React.FC = () => {
    return (
        <SupportWrapper>
            <FooterTitle className={'text-brown-300'}>Want to talk wine investing?</FooterTitle>
            <BindWithSupport>
                <div className={'imgWrap'}>
                    <Image src={LauraImg} width={40} height={40} alt={'Elaine Lau'} />
                </div>
                <div className={'links'}>
                    <a href={`mailto:hello@vinovest.co?subject=I've%20got%20a%20question%20about%20Vinovest`}>
                        hello@vinovest.co
                    </a>
                    <a
                        className={'bottomLink'}
                        href={`mailto:hello@vinovest.co?subject=I've%20got%20a%20question%20about%20Vinovest`}
                    >
                        will reply in 48hrs
                    </a>
                </div>
            </BindWithSupport>
            <BindWithSupport className={'last'}>
                <div className={'imgWrap'}>
                    <Image src={Phone} width={40} height={40} alt={'Elaine Lau'} />
                </div>
                <div className={'links'}>
                    <a href={`tel:12138982138`}>1-213-898-2138</a>
                    <a className={'bottomLink'} href={`tel:12138982138`}>
                        Monday - Friday, 10am-4pm CST
                    </a>
                </div>
            </BindWithSupport>

            <span
                id={'bbblink'}
                className={'accreditedBBB ruhzbum'}
                title={'Vinovest Inc., Wholesale Wine, Culver City, CA'}
            >
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                    className={'accreditedImg'}
                    id={'bbblinkimg'}
                    src={'https://seal-sanjose.bbb.org/logo/ruhzbum/vinovest-1284751.png'}
                    width={'300'}
                    height={'68'}
                    loading={'lazy'}
                    alt={'Vinovest Inc., Wholesale Wine, Culver City, CA'}
                />
            </span>
            <div className={'stores mt-14'}>
                <DownloadIosApp />
            </div>
        </SupportWrapper>
    );
};

export default FooterSupport;

const SupportWrapper = styled.div`
    padding-top: 30px;
    padding-bottom: 47px;
    padding-left: 66px;

    ${FooterTitle} {
        margin-bottom: 29px;
    }

    @media screen and (max-width: 1300px) {
        padding-left: 30px;
    }

    @media screen and (max-width: 767px) {
        padding: 0;
    }

    .accreditedBBB {
        /* max-width: 150px; */
        display: block;
        position: relative;
        overflow: hidden;
        width: 150px;
        height: 68px;
        margin: 0px;
        padding: 0px;
        div {
            height: 68px !important;
        }
        .accreditedImg {
            padding: 0px !important;
            border: none !important;
            width: 300px !important;
            height: 68px !important;
            overflow: hidden;
            left: 0 !important;
            top: 0 !important;
            bottom: 0 !important;
            min-width: unset !important;
            max-width: unset !important;
            min-height: unset !important;
            max-height: unset !important;
        }
    }

    .fbi_newsletter {
        border-radius: 0px !important;
    }
`;

const BindWithSupport = styled.div`
    display: flex;
    align-items: center;

    &.last {
        margin-top: 21px;
        margin-bottom: 45px;
    }
    @media screen and (max-width: 1024px) {
        &.last {
            margin-top: 21px;
            margin-bottom: 36px;
        }
    }

    .imgWrap {
        width: 40px;
        margin-right: 26px;

        img {
            display: block;
            flex-shrink: 0;
            height: auto;
            width: 100%;
        }
    }

    .links {
        a {
            font-family: var(--font-vinovest-medium);
            text-decoration: none;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 30px;
            display: block;
            color: #fae8d1;
        }

        .bottomLink {
            color: #7edf83;
        }
    }
`;
