import { createRoute } from 'next-typed-routes';

export enum ManagedRoutes {
    Portfolio = '/managed/portfolio',
    Transactions = '/managed/transactions',
    Documents = '/managed/documents',
    BeforeYouLiquidate = '/before-you-liquidate-your-protfolio',
    Liquidation = '/liquidation',
    Help = '/help',
    Invest = '/managed/invest'
}

/** Mnaged navigation links for next router */
export const managedRoutes = {
    portfolio: createRoute(ManagedRoutes.Portfolio),
    transactions: createRoute(ManagedRoutes.Transactions),
    documents: createRoute(ManagedRoutes.Documents),
    liquidation: createRoute(ManagedRoutes.Liquidation),
    beforeYouLiquidate: createRoute(ManagedRoutes.BeforeYouLiquidate),
    help: createRoute(ManagedRoutes.Help),
    invest: createRoute(ManagedRoutes.Invest)
};
