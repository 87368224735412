import type { AccessToken, IDToken } from '@okta/okta-auth-js';
import { useRouter } from 'next/router';

import { SessionStorageHelper } from '~/helpers/StorageHelper';
import { ClientsideAuthentication } from '~/stores/ClientsideAuthentication';
import { useEffect, useState } from 'react';
import { autorun } from 'mobx';

interface AuthenticationContext {
    isAuthenticated: boolean;
    isAuthLoading: boolean;
    jwt?: string;
    userId?: string;
    tokenInfo?: { accessToken?: AccessToken; idToken?: IDToken };
    email?: string;
    authStore: ClientsideAuthentication;
    firstName?: string;
    lastName?: string;
}

/**
 * Hook to check authentication status client side.
 */
export const useAuthentication = (mustAuthenticate?: boolean): AuthenticationContext => {
    const router = useRouter();
    const store = ClientsideAuthentication.getInstance();
    const { callback_pathname: callbackParam } = router.query;
    // due to vagaries of mobx, we need to store the last state to be updated by
    // the autorun, which bridges to non-mobx components
    const [lastState, setLastState] = useState<AuthenticationContext>({
        isAuthenticated: store.isAuthenticated,
        isAuthLoading: store.loading,
        jwt: store.jwt,
        userId: store.userId,
        email: store.email,
        firstName: store.firstName,
        lastName: store.lastName,
        authStore: store
    });

    if (callbackParam != null) {
        SessionStorageHelper.setItem('callback_pathname', callbackParam);
    }

    useEffect(() => {
        return autorun(() => {
            if (mustAuthenticate && !store.isAuthenticated && !store.loading) {
                void router.push(
                    `/login?callback_pathname=${encodeURIComponent(window.location.pathname + window.location.search)}`
                );
            }

            setLastState({
                isAuthenticated: store.isAuthenticated,
                isAuthLoading: store.loading,
                jwt: store.jwt,
                userId: store.userId,
                email: store.email,
                firstName: store.firstName,
                lastName: store.lastName,
                authStore: store
            });
        });
        // deps handled by autorun
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return lastState;
};
