var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"tD3yncTO3R08djbtJgFlf"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import {
  getCurrentScope,
  breadcrumbsIntegration,
  browserApiErrorsIntegration,
  BrowserClient,
  globalHandlersIntegration,
  httpClientIntegration,
  httpContextIntegration,
  makeFetchTransport,
  defaultStackParser
} from '@sentry/nextjs';

const client = new BrowserClient({
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  // Adjust this value in production, or use tracesSampler for greater control
  tracesSampleRate: 0,
  debug: process.env.NODE_ENV === 'development',
  environment: process.env.NEXT_PUBLIC_SENTRY_ENV,
  transport: makeFetchTransport,
  stackParser: defaultStackParser,
  integrations: [
    // make integrations explicit to disable browser tracing
    breadcrumbsIntegration(),
    browserApiErrorsIntegration(),
    globalHandlersIntegration(),
    httpContextIntegration(),
    httpClientIntegration()
  ],
  defaultIntegrations: false,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
});

getCurrentScope().setClient(client);
client.init();
