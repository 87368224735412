import { createRoute } from 'next-typed-routes';

/**
 * Nested Whiskey routes
 */
export enum WhiskeyRoutes {
    Invest = '/whiskey/invest',
    Portfolio = '/whiskey/portfolio',
    Activity = '/whiskey/activity',
    Guide = '/whiskey/guide',
    Documents = '/whiskey/documents'
}

/** Whiskey routes for use with next router */
export const whiskeyRoutes = {
    invest: createRoute(WhiskeyRoutes.Invest),
    portfolio: createRoute(WhiskeyRoutes.Portfolio),
    activity: createRoute(WhiskeyRoutes.Activity),
    guide: createRoute(WhiskeyRoutes.Guide),
    documents: createRoute(WhiskeyRoutes.Documents)
};
