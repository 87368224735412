import { PortfolioTypes } from '~/models/PortfolioType';

/** Options for deposit frequency from banks for credit cards. */
export enum DepositFrequency {
    once = 'once',
    week = 'week',
    month = 'month'
}

export enum MinimumInitialDeposit {
    Managed = 1000,
    Whiskey = 1000,
    Trading = 0
}

/**
 * Minimum initial deposit amount for each portfolio type
 */
export const minimumIninitialDeposit = {
    [PortfolioTypes.trading]: MinimumInitialDeposit.Trading,
    [PortfolioTypes.managed]: MinimumInitialDeposit.Managed,
    [PortfolioTypes.whiskey]: MinimumInitialDeposit.Whiskey
};

export enum DepositFrequencyAmount {
    once = 1000,
    week = 100,
    month = 500
}
