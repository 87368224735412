import React from 'react';
import classNames from 'classnames';

import { ClassNamesProps } from '~/models/ClassNamesProps';

interface CardProps extends ClassNamesProps {
    shadow?: keyof typeof shadowAmount;
    padding?: string | string[];
    borderRadius?: keyof typeof borderRadiusSizes;
}

export const Card: React.FC<CardProps> = ({ children, ...props }) => {
    const { classnames, shadow, padding, borderRadius } = props;
    const allPadding = Array.isArray(padding) ? padding.join(' ') : padding;

    return (
        <div
            className={classNames(
                allPadding || 'py-8 px-2 md:py-8 md:px-8',
                shadowAmount[shadow || 'card'],
                borderRadiusSizes[borderRadius || 'rounded'],
                classnames
            )}
        >
            {children}
        </div>
    );
};

export const shadowAmount = {
    card: 'shadow-card',
    none: 'shadow-none',
    md: 'shadow-md',
    lg: 'shadow-lg',
    xl: 'shadow-xl'
};

export const borderRadiusSizes = {
    none: 'rounded-none',
    sm: 'rounded-sm',
    rounded: 'rounded',
    md: 'rounded-md',
    lg: 'rounded-lg',
    xl: 'rounded-xl',
    full: 'rounded-full'
};
